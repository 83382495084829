
import { auth } from '../firebase';
import { AlertingPreferencesApi, AnalysisApi, ClientApi, Configuration, FraudOutreachApi, MessageApi, PracticeApi, SubscriptionApi, UserApi, XeroApi } from './generated';



const cfg: Configuration = {
  basePath: process.env.REACT_APP_MEYSEY_API_BASE || '/api',
  isJsonMime: () => false,
  accessToken: async () => {
    await auth.authStateReady();    
    const token = await auth.currentUser?.getIdToken();    
    return token || '';
  } // this is stupid , the generated code will send Authorization: Bearer where there is no token...
};

export const apiClient = {
  user: new UserApi(cfg),
  analysis: new AnalysisApi(cfg),
  xero: new XeroApi(cfg),
  message: new MessageApi(cfg),
  alertingPreferences: new AlertingPreferencesApi(cfg),
  subscription: new SubscriptionApi(cfg),
  client: new ClientApi(cfg),
  practice: new PracticeApi(cfg),
  fraudOutreach: new FraudOutreachApi(cfg),
  xeroClientConsentUrl: cfg.basePath + '/xero/consent',
  xeroPracticeClientConsentUrl: (practiceId: string, clientId: string, reconnectXeroTenantId?: string) => cfg.basePath + `/xero/consent/practice/${practiceId}/client/${clientId}${reconnectXeroTenantId ? `?reconnectXeroTenantId=${reconnectXeroTenantId}` : ``}`,
  getXeroAttachmentProxyUrl: (attachmentUrl: string) => cfg.basePath + `/xero/attachment-proxy?url=${encodeURIComponent(attachmentUrl)}`,
  getClientPracticeUserAccessRedirectUrl: (clientId: string) => cfg.basePath + `/client/${clientId}/practice-user-access-redirect`
};

